import React from "react"
import styled from 'styled-components'

const HeroDiv = styled.div`
    background-color: blue;

    min-height: 320px;

    position: relative;
   
` 

const ContentDiv = styled.div`
    height: 100%;
    width: 100%;
    background-color: #2F3061;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    z-index: 2;

    top: 0;

`

const CenterDiv = styled.div`
    height: 200px;
    width: 400px;

    display: flex;
    flex-direction: column;

`

const CenterText = styled.h2`
        color: white;
        align-self: center;
        margin:0;

        font-size: 45px;
`

const CenterTextSub = styled.h3`
        margin: 0;
        color: white;
        align-self: center;
        font-size: 20px;
`

const UpperDiv = styled.div`
    height:70%;
    display:flex;
    flex-direction: column;
`
const ContactDiv = styled.div`
    height: 20%;

    display: flex;

    justify-content: space-around;
    
`

const ContactLink = styled.a`
    font-size: 20px;
    color:#FFFFFF;
    margin:0;
`

const HeroImageDiv = styled.div`
    height: 100%;
    width: 100%;

    > * {
        height: 100%;
    }

`
const Hero = () =>(
    <HeroDiv>


        <ContentDiv>
          
            <CenterDiv>

                <UpperDiv>
                    <CenterText>Hey, I'm Zach</CenterText>
                    <CenterTextSub>Software Dev</CenterTextSub>
                </UpperDiv>

                <ContactDiv>
                    <ContactLink href="https://linkedin.com/in/zachhall-cs" >Linkedin</ContactLink>
                    <ContactLink href="https://github.com/ZachJHall">Github</ContactLink>
                    <ContactLink href="mailto:zachwebsitecontact@gmail.com">Email</ContactLink>
                    {/* <ContactLink href={Resume}>Resume</ContactLink> */}
                </ContactDiv>

            </CenterDiv>

        </ContentDiv>

    </HeroDiv>
)

export default Hero