import React from "react"
import styled from 'styled-components'

import ProjectCard from './projectCard'

import ChihuahuaRescue from '../media/chihuahuaRescueMidwestImage.png'
import Paint from '../media/digital-paint-board.png'
import Fitness from '../media/usafitness.png'
import SpinAFriend from '../media/spinAFriend.png'
import StaticGen from '../media/python-static.png'

const ProjectDiv = styled.div`
    height: auto;
    width: 100%;

    display: flex;
    flex-direction: column;

    justify-content: space-around;
    background-color: white;



` 

const IntroDiv=styled.div`
    height:auto;
    display: flex;

    justify-content:center;
    align-items: center;
`

const IntroText = styled.h2`
    font-size: 45px;
    margin: 10px 0 10px 0;
    color: #0F1217;
`
const ProjectContainer = styled.div`
    height: auto;
    width: 100%;

    display: flex;
    flex-direction: row;

    justify-content: space-around;
    background-color: white;

    flex-wrap: wrap;

`
const Projects = () =>(
    <ProjectDiv>
        <IntroDiv>
            <IntroText>Projects</IntroText>
        </IntroDiv>
        <ProjectContainer>

            <ProjectCard 
            backgroundImg = {StaticGen}
            title = "Python Static Site Generator" 
            bio = "A simple static site generator I work on from time to time"
            gitU = "https://github.com/ZachJHall/python-static-site-generator" 
            liveU = "https://static.zachhall.me/"
            />

        </ProjectContainer>
    </ProjectDiv>
)

export default Projects
